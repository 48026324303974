// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-js": () => import("./../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-js": () => import("./../src/pages/ios.js" /* webpackChunkName: "component---src-pages-ios-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-app-js": () => import("./../src/pages/privacy/app.js" /* webpackChunkName: "component---src-pages-privacy-app-js" */),
  "component---src-pages-privacy-index-js": () => import("./../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */)
}

